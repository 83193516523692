<template>
  <VueContentHolder
    v-if="showComponent"
    :class="cssClasses.wrapper"
    :style="{
      backgroundRepeat: 'no-repeat',
      overflow: 'auto',
      minHeight: '100%',
      backgroundSize: 'cover',
      background: `url('${require('@/assets/bg/modal-bg.svg')}') top left no-repeat, linear-gradient(to left, #d20051 0%, #ffa300 100%)`,
    }"
  >
    <VueContentHolder :padding="[20, 20]" v-if="contentWithGraph">
      <slot></slot>
    </VueContentHolder>
    <VueContentHolder :padding="[20, 20]">
      <a @click.prevent="closeModal" :class="cssClasses.closeButton">
        <VueIcon
          :iconName="getIcon.cross.name"
          iconColor="#ffffff"
          :width="getIcon.cross.width"
          :height="getIcon.cross.height"
        ></VueIcon>
      </a>

      <div :class="cssClasses.resultBodyTop" v-if="topTitle && topValue && !contentWithGraph">
        <VueHeadline weightLevel="3" level="2" color="white-100" :class="cssClasses.headline">{{
          topTitle
        }}</VueHeadline>
        <VueSpacer level="40"></VueSpacer>
        <VueText weightLevel="4" sizeLevel="14" color="white-100" :class="cssClasses.value">{{
          topValue | currencyFormat
        }}</VueText>
      </div>
      <VueSpacer level="60"></VueSpacer>
      <hr v-if="!contentWithGraph" />
      <VueSpacer level="60"></VueSpacer>

      <div
        :class="cssClasses.resultBodyBottom"
        v-if="bottomTitle && bottomValue && !contentWithGraph"
      >
        <VueHeadline weightLevel="3" level="2" color="white-100" :class="cssClasses.headline">{{
          bottomTitle
        }}</VueHeadline>
        <VueSpacer level="40"></VueSpacer>
        <VueText weightLevel="4" sizeLevel="14" color="white-100" :class="cssClasses.value"
          >{{ bottomValue | currencyFormat }} TL</VueText
        >
      </div>
      <BrandButton
        v-if="!contentWithGraph"
        :size="sizes.xxLarge"
        :contentAlignment="constants.flexAlignment.center"
        type="submit"
        :outlined="true"
        :padding="{ vertical: 15, horizontal: 0 }"
        @click="handleSubmit"
        :class="cssClasses.submitButton"
        ><VueText sizeLevel="6" weightLevel="3" color="red-30"
          >PERFORMANS TAKİBİ</VueText
        ></BrandButton
      >
    </VueContentHolder>
  </VueContentHolder>
</template>

<script>
import BrandButton from '@/components/brand/BrandButton/BrandButton.vue';
import COMPONENT_CONSTANTS from '@/constants/component.constants.js';
import { FLEX_JUSTIFIES } from '@/constants/flex.constants.js';
import { ICON_VARIABLES } from '@/constants/component.constants';
import VueHeadline from '@/components/shared/VueHeadline/VueHeadline.vue';
import VueIcon from '@/components/shared/VueIcon/VueIcon.vue';
import VueSpacer from '@/components/shared/VueSpacer/VueSpacer.vue';
import VueText from '@/components/shared/VueText/VueText.vue';
import VueContentHolder from '@/components/shared/VueContentHolder/VueContentHolder.vue';
import currencyFilter from '@/mixins/currencyFilter.js';

export default {
  name: 'BrandResultView',
  components: {
    VueSpacer,
    VueHeadline,
    VueContentHolder,
    BrandButton,
    VueText,
    VueIcon,
  },
  mixins: [currencyFilter],
  props: {
    showComponent: {
      type: Boolean,
      default: false,
    },
    cssStyles: {
      type: Object,
    },
    topTitle: {
      type: String,
    },
    topValue: {
      type: Number || String,
    },
    bottomTitle: {
      type: String,
    },
    bottomValue: {
      type: Number || String,
    },
    contentWithGraph: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    constants() {
      return {
        flexAlignment: FLEX_JUSTIFIES,
      };
    },
    sizes() {
      return COMPONENT_CONSTANTS.COMPONENT_SIZES;
    },
    getIcon() {
      return {
        cross: ICON_VARIABLES.cross,
      };
    },
    cssClasses() {
      return {
        wrapper: [this.$style['component-wrapper']],
        closeButton: [this.$style['close-button']],
        resultBodyTop: [this.$style['result-body-top']],
        resultBodyBottom: [this.$style['result-body-bottom']],
        headline: [this.$style['headline']],
        value: [this.$style['value']],
        submitButton: [this.$style['submit-button']],
      };
    },
    cssStyle() {
      return this.cssStyles ? { ...this.cssStyles } : null;
    },
  },
  destroyed() {
    document.documentElement.style.overflow = 'auto';
  },
  methods: {
    handleSubmit(e) {
      this.$emit('handleSubmit', e);
    },
    closeModal(e) {
      this.$emit('close', e);
    },
  },
};
</script>

<style module lang="scss">
.component-wrapper {
  height: 100%;
  display: flex;
  flex: 1;
  z-index: 1000;
  position: fixed;
  bottom: 0;
  width: 100%;
  top: 0;
}

.close-button {
  position: absolute;
  right: 20px;
  top: 20px;
}

.result-body-top,
.result-body-bottom {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;

  .headline {
    padding: 0 palette-space-level('50');
    line-height: 1.5;
  }
}
.result-body-top {
  margin-top: auto;
}
.result-body-bottom {
  margin-bottom: auto;
}
</style>
